<template>
  <v-data-table
    id="base-table"
    class="font-weight-bold tabla-index"
    v-bind="$attrs"
    v-on="$listeners"
    :headers="dataHeaders"
    :items="items"
    :loading="loading"
    :search="search"
    :server-items-length="totalRows"
    :options.sync="options"
    :footer-props="{
      itemsPerPageOptions: [15, 25, 50, 100, -1],
      showFirstLastPage: true
    }"
  >
    <!-- KEY FIELD -->
    <template v-slot:[`item.${dataHeaders[0].value}`]="{ item, value }">
      <span
        role="button"
        v-can="`edit-${section}`"
        @click="$emit('edit', item)"
      >
        {{ value }}
      </span>
    </template>

    <!-- STATUS SPA-->
    <template #item.activo="{ value }">
      <v-chip
        :color="value ? 'success' : 'default'"
        v-text="value ? 'activo' : 'inactivo'"
        small
      />
    </template>

    <!-- STATUS -->
    <template #item.active="{ value }">
      <span :class="value ? 'green--text' : 'red--text'">
        {{ value ? 'Activo' : 'Inactivo' }}
      </span>
    </template>

    <!-- UPDATED AT FIELD -->
    <template #item.updated_at="{ value }">
      <span> {{ value | date }}</span>
    </template>

    <!-- CREATE AT FIELD -->
    <template #item.created_at="{ value }">
      <span> {{ value | date }}</span>
    </template>

    <template #item.action="{ item }">
      <div
        class="d-flex flex-row justify-end justify-md-center action-container"
      >
        <!-- EDIT -->
        <v-tooltip top v-if="buttons.edit">
          <template #activator="{ on }">
            <v-btn
              class="mr-2"
              icon
              color="#8990AD"
              v-on="on"
              v-can="`edit-${section}`"
              @click="$emit('edit', item)"
            >
              <v-icon v-text="'mdi-pencil'" />
            </v-btn>
          </template>
          <span>Modificar el registro</span>
        </v-tooltip>

        <!-- DELETE -->
        <v-tooltip top v-if="buttons.delete">
          <template #activator="{ on }">
            <v-btn
              v-if="item.name === 'Español'"
              class="mr-2"
              icon
              color="#8990AD"
              disabled
            >
              <v-icon v-text="'mdi-delete'" />
            </v-btn>
            <v-btn
              v-else
              class="mr-2"
              icon
              color="#8990AD"
              v-on="on"
              v-can="`delete-${section}`"
              @click="$emit('delete', item)"
            >
              <v-icon v-text="'mdi-delete'" />
            </v-btn>
          </template>
          <span>Eliminar el registro</span>
        </v-tooltip>
        <slot name="custom-button" v-bind:item="item"></slot>
      </div>
    </template>

    <!-- Agregamos todos los slots de la tabla al Slot Principal -->
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'BaseTable',
  props: {
    headers: {
      type: Array,
      required: true
    },
    section: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    totalRows: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    search: String,
    actions: {
      type: Array,
      default: () => ['edit', 'delete']
    },
    buttons: {
      type: Object,
      default: () => ({ edit: true, delete: true })
    },
    savePage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dataHeaders() {
      return this.headers
    }
  },
  data() {
    return {
      options: {}
    }
  },
  mounted() {
    const options = this.getOptions()
    if (options) this.options = options

    if (this.actions.length > 0) {
      const item = this.dataHeaders.find(item => item.value === 'action')
      if (!item)
        this.dataHeaders.push({
          text: 'Acciones',
          value: 'action',
          width: '10rem',
          align: 'center',
          sortable: false
        })
    }
  },
  watch: {
    /**
     * Observamos los cambios de la propiedad items para asignar
     * la pagina que guardamos en el localStorage
     */
    items: {
      handler() {
        if (this.savePage) this.setPage()
      }
    },
    options: {
      handler() {
        this.saveOptions()
        this.$emit('options', this.options)
      },
      deep: true
    }
  },
  methods: {
    /**
     * Set the table page from the localStorage
     */
    setPage() {
      const { page } = JSON.parse(
        localStorage.getItem(`table-${this.$route.name}`)
      )
      this.options.page = page || 1
    },
    getOptions() {
      return null
      /*
      if (this.$route) {
        const routeName = `table-${this.$route.name}`
        return JSON.parse(localStorage.getItem(routeName))
      } else return null
      */
    },

    saveOptions() {
      /*
      if (this.$route) {
        const routeName = `table-${this.$route.name}`
        localStorage.setItem(routeName, JSON.stringify(this.options))
      }
      */
    }
  }
}
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: #edf2f7;
}
.tabla-index {
  color: #001737 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-weight: bold;
  font-size: 12px;
}

@media only screen and (max-width: 1127px) {
  .text-center {
    padding: 0 0 !important;
  }
}
@media only screen and (max-width: 600px) {
  /* Alinear items de accion a la derecha */
  .action-container:last-child {
    margin-right: -17px;
  }
}
</style>
