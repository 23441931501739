<template>
  <v-row align="center">
    <!-- BUSCADORR -->
    <v-col cols="12" md="5" v-if="buttons.search">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar..."
        placeholder="Buscar..."
        dense
        single-line
        hide-details
        clearable
        light
        autofocus
        @click:clear="handleClear"
        @keyup.esc="handleClear"
        @input="debounce"
      />

      <slot name="custom-buttons"></slot>
    </v-col>
  </v-row>
</template>
<script>
import { debounce } from '@/utils'
export default {
  name: 'BaseButtonsList',
  props: {
    buttons: {
      type: Object,
      default: () => ({ add: true, refresh: true, search: true, print: false })
    },
    selected: {
      type: Array,
      default: () => []
    },
    section: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      debounce: null
    }
  },
  created() {
    this.debounce = debounce(() => {
      if (this.search.length > 2 || this.search.length === 0)
        this.$emit('search', this.search)
    })
  },
  methods: {
    handleClear() {
      this.search = ''
      this.$emit('search', this.search)
    }
  }
}
</script>
