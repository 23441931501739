var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({staticClass:"font-weight-bold tabla-index",attrs:{"id":"base-table","headers":_vm.dataHeaders,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"server-items-length":_vm.totalRows,"options":_vm.options,"footer-props":{
    itemsPerPageOptions: [15, 25, 50, 100, -1],
    showFirstLastPage: true
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:("item." + (_vm.dataHeaders[0].value)),fn:function(ref){
  var item = ref.item;
  var value = ref.value;
return [_c('span',{directives:[{name:"can",rawName:"v-can",value:(("edit-" + _vm.section)),expression:"`edit-${section}`"}],attrs:{"role":"button"},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.activo",fn:function(ref){
  var value = ref.value;
return [_c('v-chip',{attrs:{"color":value ? 'success' : 'default',"small":""},domProps:{"textContent":_vm._s(value ? 'activo' : 'inactivo')}})]}},{key:"item.active",fn:function(ref){
  var value = ref.value;
return [_c('span',{class:value ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(value ? 'Activo' : 'Inactivo')+" ")])]}},{key:"item.updated_at",fn:function(ref){
  var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value)))])]}},{key:"item.created_at",fn:function(ref){
  var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value)))])]}},{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-end justify-md-center action-container"},[(_vm.buttons.edit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:(("edit-" + _vm.section)),expression:"`edit-${section}`"}],staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD"},on:{"click":function($event){return _vm.$emit('edit', item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-pencil')}})],1)]}}],null,true)},[_c('span',[_vm._v("Modificar el registro")])]):_vm._e(),(_vm.buttons.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [(item.name === 'Español')?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD","disabled":""}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-delete')}})],1):_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:(("delete-" + _vm.section)),expression:"`delete-${section}`"}],staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD"},on:{"click":function($event){return _vm.$emit('delete', item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-delete')}})],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar el registro")])]):_vm._e(),_vm._t("custom-button",null,{"item":item})],2)]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }